import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import CenteredSpinner from "../../_components/CenteredSpinner";
import { useMembershipPaymentInformation } from "../api/getUserMembershipPaymentInformation"


const TEXT_LABEL_PROPS = {
    color: '#7B8887',
    mr: ['0px', '0px'],
    pb:['10px', 0],
    letterSpacing: '0.6px',
}

const TEXT_VALUE_PROPS = {
    letterSpacing: '0.7px',
}

const CardInformation = ({data}) => {

    const handleClick = () => {
        // open updateUrl in new tab
        if (data?.update_payment_url){
            window.open(data?.update_payment_url, '_blank');
        }
    }

    return (

        <Flex justify={['center', 'flex-start']} mt={['0px', '0px']} direction={['column']} pb={['20px']}>
            <Flex>
                <Text {...TEXT_LABEL_PROPS}>
                    <FormattedMessage
                        id={'membershipPage.paymentInformation.cardType'}
                        defaultMessage={'Card Type'}
                    />
                </Text>
                <Text {...TEXT_VALUE_PROPS} ml={[2]} textTransform={'uppercase'}>
                    {data?.card?.card_type}
                </Text>
            
            </Flex>
            <Flex>
                <Text {...TEXT_LABEL_PROPS}>
                    <FormattedMessage
                        id={'membershipPage.paymentInformation.cardNumber'}
                        defaultMessage={'Card Number'}
                    />
                </Text>
                <Text {...TEXT_VALUE_PROPS} ml={[2]}>
                    {`**** **** **** ${data?.card?.last_four_digits}`}
                </Text>
            </Flex>
            <Flex>
                <Text {...TEXT_LABEL_PROPS}>
                    <FormattedMessage
                        id={'membershipPage.paymentInformation.expiryDate'}
                        defaultMessage={'Expiry Date'}
                    />
                </Text>
                <Text {...TEXT_VALUE_PROPS} ml={[2]}>
                    {`${data?.card?.expiry_month}/${data?.card?.expiry_year}`}
                </Text>
            </Flex>
            {
                data?.update_payment_url && (
                    <Flex w={['full']} justify={['center']} pt={['15px']} pb={['5px']}>
                        <Button 
                            variant={'outline'} 
                            type={'button'} 
                            onClick={handleClick}
                        >
                            <FormattedMessage
                                id={'membershipPage.paymentInformation.updateCard'}
                                defaultMessage={'Update Card'}
                            />
                        </Button>
                    </Flex>
                )
            }
        </Flex>

    )
}

const QRCodeInformation = () => {
    return (
        <Flex justify={['center', 'flex-start']} mt={['0px', '0px']} direction={['column']} pb={['20px']}>
            <Flex>
                <Text>
                    <FormattedMessage 
                        id={'membershipPage.paymentInformation.qrCode'}
                        defaultMessage={'You are currently paying with QR Code'}
                    />
                </Text>
            
            </Flex>
        </Flex>
    )
}

export const PaymentInformation = () => {
    /*
        Display the payment information for the user.
        If the user is paying with card (auto_collect is True), show a link (update_payment_url) to update the card.
        If the user is paying with QR Code (auto_collect is False), then just display text that the user is paying with QR Code.

            "update_payment_url": update_payment_url,
            "auto_collect": True,
            "card": subscription_customer.subscription_data.get("card", {})

            {"card": {
            "card_id": "5902047000000124067", 
            "card_type": "visa", 
            "is_backup": false, 
            "is_primary": false, 
            "expiry_year": 2030, 
            "expiry_month": 1, 
            "gateway_card_id": "", 
            "payment_gateway": "test_gateway", 
            "last_four_digits": "1111", 
            "gateway_customer_id": "", 
            "is_stripe_india_international_card": true}
    */
    const { data, isLoading, isError } = useMembershipPaymentInformation();
    
    
    return (
        <Box>
            <Heading>
                <FormattedMessage
                    id="membershipPage.paymentInformation.title"
                    defaultMessage="Payment Information"
                />

            </Heading>
            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage
                        id={'membershipPage.paymentInformation.subtitle.details'}
                        defaultMessage={'Card Information'}
                    />
                </Text>
            </Box>
            {
                isLoading ? (
                    <CenteredSpinner />
                ) : isError ? (
                    <Text>
                        <FormattedMessage
                            id="membershipPage.paymentInformation.error"
                            defaultMessage="Error loading payment information"
                        />
                    </Text>
                ) : (
                    <>
                        {
                            data?.auto_collect ? (
                                <CardInformation 
                                    data={data} 
                                />
                            ) : (
                                <QRCodeInformation />
                            )
                        }
                    </>
                )
            }

        </Box>
    )
}
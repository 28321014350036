import React, { useRef } from 'react';
import { Box, VStack, Divider, Stack, HStack, IconButton, FormLabel, Flex, SimpleGrid, Text, Checkbox, useDisclosure,
 Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button, Input } from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useCountry } from 'src/_libs/useCountry';
import { ContentContainer, ButtonStack } from './common';
import { Field } from 'react-final-form';
import { required } from 'src/_helpers';
import { useSubscriptionPlanCollection } from '../api/getSubscriptionPlans';
import { useApplyStore, STEP_PAGES } from '../state/apply';
import { formatDate } from 'src/Membership/utils';
import { DoubleChevronArrowDown } from 'src/_images/icons/DoubleChevronArrowDown';
import { css, keyframes } from '@emotion/react';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

function shouldBeDisabled(values) {
    // check that it is checked
    if (values.agreement_data?.name && values.agreement_data?.date && values.agreement_checked) {
        return false;
    }
    return true;
}

const PlanName = ({planCode})  => {
    const countryData = useCountry();
    const { data, isLoading } = useSubscriptionPlanCollection({countryCode: countryData?.country});

    if (isLoading) {
        return <></>;
    }

    return (
        <Text fontWeight="bold">{(data?.plans || []).find((item) => item.code == planCode)?.name}</Text>
    )
}


function getTotalMembers(dependents){
    // {SPOUSE: [], 'PARENT': [], 'CHILDREN': []}
    // get total members
    let totalMembers = 0;
    for (const key in dependents) {
        totalMembers += dependents[key].length;
    }
    return totalMembers + 1;
}

function getMemberNames(values, formatMessage){
    // {SPOUSE: [], 'PARENT': [], 'CHILDREN': []}
    // get total members
    let memberNames = [formatMessage(
        {id: 'format.fullName', defaultMessage: '{givenName} {familyName}'},
        {givenName: values.first_name, familyName: values.last_name}
    )];
    const dependents = values?.dependents || [];
    for (const key in dependents) {
        if (dependents[key].length === 0) {
            continue;
        }
        memberNames.push(dependents[key].map((item) => formatMessage(
            {id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, 
            {givenName: item.first_name, familyName: item.last_name}
        )));
    }
    // join all names in arary to string with coma
    return memberNames.join(', ').replace(/, $/, '');
}

const PlanReview = ({values}) => {
    const { formatMessage } = useIntl();

    return (
        <Box p={5} borderRadius="md" border="1px solid #E2E8F0" backgroundColor="white" boxShadow="md">   
            {/* Plan Details */}
            <Box mt={2}>
                <Text fontWeight="bold" fontSize={[20]} mb={2}>
                    <FormattedMessage id="membershipPage.paymentReview.planDetails.title" defaultMessage="Plan details" />
                </Text>
                <Divider />
                <Stack spacing={2} mt={[2]}>
                    <Stack justify="space-between">
                        <Text fontSize="sm" color="gray.500">
                            <FormattedMessage id="membershipPage.paymentReview.label.plan" defaultMessage="Plan Name" />
                        </Text>
                        <PlanName planCode={values.plan_code} />
                    </Stack>
                    <Stack justify="space-between">
                        <Text fontSize="sm" color="gray.500">
                            <FormattedMessage id="membershipPage.paymentReview.label.plancode" defaultMessage="Plan Code" />
                        </Text>
                        <Text fontWeight="bold">{values.plan_code}</Text>
                    </Stack>
                    <Stack justify="space-between">
                        <Text fontSize="sm" color="gray.500">
                            <FormattedMessage id="membershipPage.paymentReview.label.covered_members" defaultMessage="Covered members" />
                        </Text>
                        <Flex>

                            <Text fontWeight="bold">
                                {getTotalMembers(values.dependents)}
                                &nbsp;
                                <FormattedMessage id="membershipPage.paymentReview.label.totalMembers" defaultMessage=" Members" />
                                {' - '}
                            </Text>
                            &nbsp;
                            <Text color={'#405159'} >
                                { ` ${getMemberNames(values, formatMessage)}` }
                            </Text>
                        </Flex>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}



const AgreementField = ({inputValue, onChange}) => {
    const { formatMessage } = useIntl();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ hasScrolled, setHasScrolled ] = React.useState(false);
    const [ name, setName ] = React.useState('');
    const [date, setDate] = React.useState(new Date().toISOString().split('T')[0]); // Set today's date
    const contentRef = useRef(null);


    React.useEffect(() => {
        if (contentRef.current) {
          const contentHeight = contentRef.current.scrollHeight;
          const modalHeight = contentRef.current.clientHeight;
          if (contentHeight <= modalHeight) {
            setHasScrolled(true);
          }
        }
    }, [isOpen]);

    const handleClose = () => {
        onChange(false, {});
        setHasScrolled(false);
        setName('');
        onClose();
    };

    const handleCheckboxChange = (e) => {
        onOpen();
      };

    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        setHasScrolled(true);
      }
    };
  
    const handleConfirm = () => {
      if (name && date) {
        onChange(true, {name, date, agreement_date: date});
        onClose();
      }
    };

    const scrollToBottom = () => {
        if (contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    };

    return (
        <>
            <VStack spacing={4}>
                <Checkbox 
                    id={'agreement'}
                    isChecked={inputValue == true ? true : false}
                    onChange={handleCheckboxChange}
                >
                    <Text fontSize={14}>
                        <FormattedMessage 
                            id={'membershipPage.apply.review.agreement'}
                            defaultMessage={'I agree to the terms and conditions.'}
                        />
                    </Text>
                </Checkbox>
            </VStack>
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent px={[4]} py={[6]}>
                <ModalHeader>
                    <FormattedMessage
                        id={'membershipPage.apply.review.agreement.title'}
                        defaultMessage={'Customer Agreement'}
                    />
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody mx={['10px']} onScroll={handleScroll} ref={contentRef} style={{ maxHeight: '320px', overflowY: 'auto' }}>
                    {/* Replace this with your actual agreement policy content */}
                    <Text
                        whiteSpace={'pre-wrap'}
                        lineHeight={'1.5'}
                    >
                        <FormattedMessage 
                            id={'membershipPage.apply.review.agreement.content'}
                            defaultMessage={"Signature Required - All applicants age 18 and older must read and sign below.\nBy signing this form you agree to the following:\n\n1. The answers in this application are true and complete to the best of my knowledge and belief.\n2. The children listed on this application are my legal dependents.\n3. I understand that if I intentionally omit or provide false information on or in relation to this application, then this policy may be cancelled.\n4. I have read this entire application, or it has been read to me.\n5. No coverage shall be in force until Beforedent processes this application and Beforedent has notified me.\n6. This application will become part of the contract between Beforedent and me.\n7. I authorize Beforedent to electronically transmit the information contained in this application."}
                            />
                    </Text>
                    {!hasScrolled && (
                        <Flex
                            justify={['flex-end']}
                            align={['center']}
                            mt={[4]}
                            position={['absolute']}
                            bottom={['4rem']}
                            right={['3rem']}
                            
                        >
                            <IconButton
                                icon={<DoubleChevronArrowDown />}
                                variant={['outline']}
                                bg={['white']}
                                size={'circle'}
                                borderRadius={['50%']}
                                onClick={scrollToBottom}
                                css={css`
                                  animation: ${bounce} 2s infinite;
                                `}
                            >
                                <FormattedMessage 
                                    id={'membershipPage.apply.review.agreement.scroll'}
                                    defaultMessage={'Scroll to Bottom'}
                                />
                            </IconButton>
                        </Flex>
                    )}
                </ModalBody>
                <ModalFooter >
                    {hasScrolled && (
                        <Stack direction={['column']} spacing={[4]}>
                            <Text fontWeight={'bold'}>
                                <FormattedMessage 
                                    id={'membershipPage.apply.review.agreement.sign'}
                                    defaultMessage={'* I have read and agree to the contents of this application by signing below.'}
                                />
                            </Text>
                            <SimpleGrid columns={[2]} templateColumns={['3fr 2fr']} gap={['10px']}>
                                <VStack align={['flex-start']}>
                                    <FormLabel>
                                        <FormattedMessage 
                                            id={'membershipPage.apply.review.name'}
                                            defaultMessage={'Name'}
                                        />
                                    </FormLabel>
                                    <Input
                                        mt={[0]}
                                        placeholder={formatMessage({id: 'membershipPage.apply.review.name.placeholder', defaultMessage: 'Type your full name'})}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </VStack>
                                <VStack align={['flex-start']}>
                                    <FormLabel >
                                        <FormattedMessage 
                                            id={'membershipPage.apply.review.date'}
                                            defaultMessage={'Date'}
                                        />
                                    </FormLabel>
                                    <Input
                                        mt={[0]}
                                        isDisabled={true}
                                        _disabled={{
                                            color: '#000',
                                            border: 'none'
                                        }}
                                        value={formatDate(date)}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </VStack>
                            </SimpleGrid>
                            <Flex w={['full']} justify={['flex-end']}>
                                <Button onClick={handleConfirm} isDisabled={!name || !date}>
                                    <FormattedMessage 
                                        id={'membershipPage.apply.review.button.confirm'}
                                        defaultMessage={'I Accept'}
                                    />
                                </Button>
                            </Flex>
                        </Stack>
                    )}
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export const ApplyStepReview = ({values, form, isSubmitting, setCurrentStep, isShowing}) => {
    const { formatMessage } = useIntl();
    const setPage = useApplyStore(state => state.setPage);

    const handlePrev = () => {
        setCurrentStep(STEP_PAGES.PRIMARY, 'prev');
    }

    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <ContentContainer>
                <PlanReview values={values} />
                <Field name="agreement_checked" type="checkbox" validate={required}>
                    {({input, meta}) => (
                        <AgreementField 
                            inputValue={input.checked} 
                            onChange={(isChecked, value) => {
                                input.onChange(isChecked);
                                form.change('agreement_data', value);
                            }}
                        />
                    )}
                </Field>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    isDisabledNext={shouldBeDisabled(values)}
                    isSubmit={true}
                    isLoadingNext={isSubmitting}
                    textPrevious={formatMessage({id: 'membershipPage.apply.review.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.apply.review.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    )
}
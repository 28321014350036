export const categories = {
  caries: 'caries',
  ortho: 'ortho',
  color: 'color',
  habits: 'habits',
  gums: 'gums',
  general: 'general',
  others: 'others'
};

export const categoryClassPrefix = 'ce-category-block';

export const categoryClasses = {
  caries: categoryClassPrefix + '--caries',
  ortho: categoryClassPrefix + '--ortho',
  color: categoryClassPrefix + '--color',
  habits: categoryClassPrefix + '--habits',
  gums: categoryClassPrefix + '--gums',
  general: categoryClassPrefix + '--general',
  others: categoryClassPrefix + '--others',
};

export const categoryColors = {
  caries: '#DC4E41',
  ortho: '#00574F',
  color: '#367FFF',
  habits: '#daff00',
  gums: '#ffa500',
  general: '#367FFF',
  others: '#367FFF'
};

export const toothNumbersTag = 'TOOTHNUMBERS';
export const toothNumbersClass = 'cdx-toothnumbers';
export const toothTag = 'TOOTH';
export const toothClass = 'cdx-tooth';

const cariesIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#DC4E41" />
</svg>
`;
const gumsIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#ffa500" />
</svg>
`;
const habitsIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#daff00" />
</svg>
`;
const orthoIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#00574F" />
</svg>
`;

const colorIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#367FFF" />
</svg>
`;

const generalIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#367FFF" />
</svg>
`;

const othersIconString = `
<svg viewbox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="10" fill="#367FFF" />
</svg>
`;

export const categoryIcons = {
  caries: cariesIconString,
  gums: gumsIconString,
  habits: habitsIconString,
  ortho: orthoIconString,
  color: colorIconString,
  general: generalIconString,
  others: othersIconString,
};

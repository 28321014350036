import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
    fonts: {
      heading: `'Proxima Nova', Helvetica`,
      body: `'Proxima Nova', Helvetica, sans-serif`,
    },
    colors: {
      brand: {
        500: "#273238",
      },
      primary: {
        50: "#e0f7f3",
        100: "#b3e8dd",
        200: "#80dac6",
        300: "#4dcbae",
        400: "#1abf97",
        500: "#44C5A6",
        600: "#38a38a",
        700: "#2c826e",
        800: "#206153",
        900: "#143f38",
      },
      progressColor: {
        50: '#ddfffa',
        100: '#b6f6ee',
        200: '#8eefe3',
        300: '#64e8d8',
        400: '#3ce1cd',
        500: '#26c8b3',
        600: '#179b8b',
        700: "#086f64",
        800: "#00443c",
        900: "#001815",
      },
      steelBlue: {
        50: "#e0e7f3",
        100: "#b3c3dd",
        200: "#809ec6",
        300: "#4d79ae",
        400: "#2a5c94", // Darker shade for hover
        500: "#527499", // Default background color
        600: "#445f7d",
        700: "#374a62",
        800: "#293646",
        900: "#1b222a",
      },
      grayishBlue: {
        50: "#EBEFF3",
        100: "#CBD6DF",
        200: "#ACBBCB",
        300: "#8DA1B7",
        400: "#6E86A3",
        500: "#405159", // Primary color
        600: "#3A4A53",
        700: "#33424B",
        800: "#2B3943",
        900: "#242F3B",
      },
      customDark: {
        50: "#d8dee0",
        100: "#b2bcc0",
        200: "#8c9b9f",
        300: "#66797f",
        400: "#506068",
        500: "#273238", // Default background color
        600: "#20292d",
        700: "#192023",
        800: "#12171a",
        900: "#0b0e10",
      },
      customBlue: {
        50: "#e3f0ff",
        100: "#bad8ff",
        200: "#8fc0ff",
        300: "#65a8ff",
        400: "#3a90ff",
        500: "#2F80ED", // Default background color
        600: "#2567c1",
        700: "#1c4e94",
        800: "#123568",
        900: "#091c3c",
      },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: "30px",
                textTransform: "uppercase",
                py: "12px",
                px: "20px",
                fontSize: "16px",
                fontWeight: "normal",
            },
            defaultProps: {
                variant: "solid",
                size: "md",
            },
            variants: {
                cancel: {
                    bg: '#E95252',
                    color: "#FFFFFF",
                    _hover: {
                        bg: '#C73F3F'
                    },
                    _disabled: {
                        bg: "#C7D3D9",
                        color: "#A0B1BA"
                    }
                },
                steelBlue: {
                    bg: '#527499',
                    color: "#FFFFFF",
                    _hover: {
                        bg: '#3E5C80'
                    },
                    _disabled: {
                        bg: "#A0AEC0",
                        color: "#E0E0E0"
                    }
                },
                blue: {
                    bg: '#2F80ED',
                    color: "#FFFFFF",
                    _hover: {
                        bg: '#1366D9'
                    },
                    _disabled: {
                        bg: "#A0C4F5",
                        color: "#E0E0E0"
                    }
                },
                dark: {
                    bg: "#273238",
                    color: "#F7F7F7",
                    _hover: {
                        bg: "#3E5159"
                    },
                    _disabled: {
                        bg: "#C7D3D9",
                        color: "#A0B1BA"
                    }
                },
                solid: (props) => {
                    const { colorScheme = "primary" } = props; // Default to grayishBlue if no colorScheme is provided
                    return {
                        bg: `${colorScheme}.500`,
                        color: "#fff",
                        _hover: { bg: `${colorScheme}.400` },
                        _active: { bg: `${colorScheme}.600` },
                    }
                },
                outline: (props) => {
                    const { colorScheme = "grayishBlue" } = props; // Default to grayishBlue if no colorScheme is provided
                    return {
                      borderColor: `${colorScheme}.500`,
                      color: `${colorScheme}.500`,
                      _hover: {
                        bg: `${colorScheme}.50`,
                      },
                      _active: {
                        bg: `${colorScheme}.100`,
                      },
                      _disabled: {
                        borderColor: `${colorScheme}.200`,
                        color: `${colorScheme}.200`,
                      },
                    };
                },
                outlineDefault: {
                    bg: 'transparent',
                    borderColor: "#405159",
                    borderWidth: "1px",
                    color: "#405159",
                    _hover: {
                        border: "1px solid",
                        borderColor: "#405159",
                        borderWidth: "1px",
                        bg: 'transparent'
                    },
                    _disabled: {
                        borderColor: "#C7D3D9",
                        borderWidth: "1px",
                        bg: 'transparent',
                        color: "#A0B1BA",
                    }
                }
            },
            sizes: {
                circle: {
                  // Example: 40px circle
                    h: '34px',
                    w: '34px',
                    minWidth: '34px',
                    borderRadius: 'full',
                    fontSize: 'sm',
                    px: 0, // no extra horizontal padding
                },
                md: {
                    px: "20px",
                    py: "10px",
                    minWidth: "130px"
                },
                smd: {
                    px: "20px",
                    py: "8px",
                }
            }
        },
        FormLabel: {
          baseStyle: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#7A8F99',
            marginBottom: '5px',
            textTransform: 'uppercase',
          },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    borderColor: '#405159',
                    _checked: {
                        bg: '#405159',
                        borderColor: '#405159',
                    
                    }
                }
            }
        },
        Input: {
            variants: {
                outline: {
                    field: {
                        border: "1px solid #405159",
                        borderColor:  "#405159",
                        color: "#3E5159",
                        _placeholder: {
                            color: "#7A8F99"
                        }
                    }
                }
            }
        },

        Radio: {
            baseStyle: {
                control: {
                    borderColor: '#E2E8F0', // Border color
                    borderRadius: '50%',
                    _hover: {
                        borderColor: '#405159', // Border color on hover
                    },
                    _checked: {
                        bg: '#405159', // Background color when checked
                        borderColor: '#405159', // Border color when checked
                    },
                },
            },
        },
        ModalHeader: {
            baseStyle: {
                fontSize: "18px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                fontWeight: "normal",
                boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.08)',
                filter: 'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))',
                color: '#12171A',
                alignItems: 'center',
                height: '70px',
                fontWeight: 'normal',
                zIndex: 2,
            },
        }
    }
})
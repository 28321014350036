import { useMutation } from 'react-query';

import { createInvoicePaymentUrl as save } from './services';

import { QUERY_KEY_NAMES } from './constants';

const saveQuery = async ({ data }) => {
    return save(data);
}


export const useUserInvoicePaymentUrl = () => {
    return  useMutation({
        mutationKey: QUERY_KEY_NAMES.CREATE_INVOICE_PAYMENT_URL,
        mutationFn: (data) => {        
            return saveQuery({ data });
        },
    });

}
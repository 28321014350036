import React from 'react';
import { FormattedMessage } from 'react-intl';

export const CategoryTitle = ({category}) => {
    if (category === 'CARIES') {
        return (
            <FormattedMessage 
                id={`toothChart.category.CARIES`}
                defaultMessage='Caries'
            />
        )
    } else if (category === 'TOOTH') {
        return (
            <FormattedMessage 
                id={`toothChart.category.TOOTH`}
                defaultMessage='Tooth'
            />
        )
    } else if (category === 'COLOR') {
        return (
            <FormattedMessage 
                id={`toothChart.category.COLOR`}
                defaultMessage='Color'
            />
        )
    } else if (category === 'GUMS') {
        return (
            <FormattedMessage 
                id={`toothChart.category.GUMS`}
                defaultMessage='Gums'
            />
        )
    } else if (category === 'HABITS') {
        return (
            <FormattedMessage 
                id={`toothChart.category.HABITS`}
                defaultMessage='Habits'
            />
        )
    } else if (category === 'ORTHO') {
        return (
            <FormattedMessage 
                id={`toothChart.category.ORTHO`}
                defaultMessage='Ortho'
            />
        )
    } else if (category === 'MULTIPLE') {
        return (
            <FormattedMessage 
                id={`toothChart.category.MULTIPLE`}
                defaultMessage='Multiple'
            />
        )
    } else {
        return (
            <FormattedMessage 
                id={`toothChart.category.OTHERS`}
                defaultMessage='Others'
            />
        )
    }
}

import React from "react";
import { Box,  Alert, AlertIcon, AlertTitle, AlertDescription, useDisclosure } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { QuickModal } from "./QuickModal";
import { useHistory } from 'react-router-dom';
import { useMembershipPaymentStatus } from "../api/getUserMembershipPaymentStatus";

export const PaymentNeeded = () => {
    const history = useHistory();
    const { data, isLoading, isFetching }  = useMembershipPaymentStatus();
    const { isOpen, onClose, onOpen } = useDisclosure();


    const handlePaymentClick = () => {
        history.push("/settings/membership_payment_history");
        /*if (data?.payment_status?.checkout_url){
            window.location.href = data?.payment_status?.checkout_url;
        } else {
            onOpen();
        }*/
    }

    if (isLoading || isFetching) {
        return <></>;
    }

    if (data?.payment_status?.open_invoices > 0){
        return (
            <Box pb={['10px']}>
                <QuickModal
                    isOpen={isOpen}
                    onClose={onClose}
                    header={
                        <FormattedMessage
                            id={'membershipPage.paymentNeeded.header'}
                            defaultMessage={'Payment Required!'}
                        />
                    }
                    desciption={
                        <FormattedMessage
                            id={'membershipPage.paymentNeeded.description'}
                            defaultMessage={'There was an issue with your invoice. Please contact us to make a payment'}
                        />
                    }
                    onCancel={onClose}
                />
                <Alert status='error' onClick={handlePaymentClick}>
                    <AlertIcon />
                    <Box pl={['5px']}>
                        <AlertTitle>
                            <FormattedMessage
                                id={'membershipPage.paymentNeeded.alert.title'}
                                defaultMessage={'Payment Required!'}
                            />
                        </AlertTitle>
                        <AlertDescription>
                            <FormattedMessage
                                id={'membershipPage.paymentNeeded.alert.description'}
                                defaultMessage={'Click here to make a payment'}
                            />
                        </AlertDescription>
                    </Box>
                </Alert>
            </Box>
        );
    }
    return <></>;
}
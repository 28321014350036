import React from "react";
import { useUserInvoicePaymentUrl } from "../api/createUserInvoicePaymentUrl";
import { Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "../../_images/icons/ChevronRightIcon";
import { FormattedMessage } from "react-intl";

export const InvoicePaymentButton = ({id}) => {
    const createUrlMutation = useUserInvoicePaymentUrl()

    const handleClick = () => {
        createUrlMutation.mutate({id}, {
            onSuccess: (data) => {
                const url = data.checkout_url; // Adjust based on your API response structure
                window.location.href = data.checkout_url;
            },
            onError: (error) => {
                console.error('Error creating payment URL:', error);
            }
        });
    };
    

    return(
        <Button 
            variant={'outline'}
            colorScheme={'red'} 
            type={'button'} 
            isLoading={createUrlMutation.isLoading} 
            onClick={handleClick}
            rightIcon={<ChevronRightIcon />}
        >
            <FormattedMessage 
                id={'membershipPage.invoiceList.payInvoice'}
                defaultMessage={'Pay Invoice'}
            />
        </Button>
    )
}
import { API_URLS } from '../_config';

import { authHeader, handleResponse } from '../_helpers';

import storage from '../_helpers/storage'

export const services = {
  fetchReportDates,
  createRequestGenerative,
  createRequestOrphanage,
  checkUserReportStatus,
  requestInspection, 
  generateUserReport,
  fetchRequestList,
  fetchLatestRequest,
  fetchReport
}

function requestInspection(reportId){
  const requestUrl = API_URLS.requestInspection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{reportId}", reportId);
  return fetch(url, requestOptions).then(handleResponse);

}

function createRequestOrphanage(data){
  const requestUrl = API_URLS.createRequestOrphanage;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(requestUrl.url, requestOptions).then(handleResponse);

}

function createRequestGenerative(data){
  const requestUrl = API_URLS.createRequestGenerative;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(requestUrl.url, requestOptions).then(handleResponse);

}

function generateUserReport(requestId=null){
  const requestUrl = API_URLS.generateUserReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{requestId}", requestId);
  return fetch(url, requestOptions).then(handleResponse);
}


function checkUserReportStatus(requestId=null){
  const requestUrl = API_URLS.checkUserReportStatus;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{requestId}", requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchReportDates(profile=null, lastTs=null, page=0){
  const requestUrl = API_URLS.fetchRequestDates;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  if (profile !=  null) {
    url = url + `?profile=${profile}&last_ts=${lastTs ? lastTs : ''}`;
  } else {
    url = url + `?last_ts=${lastTs ? lastTs : ''}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchLatestRequest(profile=null) {
  const requestUrl = API_URLS.fetchLatestRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  if (profile !=  null) {
    url = url + `?profile=${profile}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRequestList(query) {
  const requestUrl = API_URLS.fetchRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?' + new URLSearchParams(query);
  //if (storage.getIsDentalType() || storage.getIsOrphanage()){
  //  
  //} else {
  //  url = url + "&type=GENERATIVE";
  //}
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchReport(requestId) {
  const requestUrl = API_URLS.fetchReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{requestId}", requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

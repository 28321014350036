

import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

import { fetchMembershipPaymentInformation as getQuery } from './services';
import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_MEMBERSHIP_PAYMENT_INFORMATION;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async () => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return getQuery()
}

export const useMembershipPaymentInformation = () => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME ],
        queryFn: () => queryRequest(), // in brackets because im parsing the object in queryRequest
      });
}
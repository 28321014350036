import React, { useEffect, useRef, useState } from 'react';
import { REPORTCHART_TYPES, GENERATIVE_REPORTCHART_TYPES } from '../_config';
import { 
  Box, 
  Text,
  Flex,
  Grid,
  Button,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { FormattedMessage } from 'react-intl';
import { toUpper } from 'lodash';

import { ReadOnlyEditor } from './Editor/ReadOnlyEditor';
import { ToothSetModern } from '../_components/ToothSetModern';

import { CategoriesFilterSelect } from './CategoriesFilterSelect';
import { CategorytDot } from './CategoryDot';
import { CategoryTitle } from './CategoryTitle';
import { useReportStore } from './state/report';
import { ReportMoreInfo } from './components/ReportMoreInfo';
import { NoReportIcon } from '../_images/icons/NoReportIcon';
import storage from '../_helpers/storage';
import { PrintPreviewReportSection, ReportPrintPreview } from './components/ReportPreview';
import { isCompleted } from '../_helpers/reports';
import { PrintIcon } from '../_images/icons/PrintIcon';
import { HealthyReportSection } from './components/HealthyReportSection';

const CategoryItem = ({category}) => {
  return (
    <Flex
      borderRadius={['30px']}
      align={'center'}
      bg={'#fff'}
      pr={['15px']}
      py={['4px']}
    >
      <CategorytDot category={category} width={['10px']} height={['10px']} marginRight={['8px']} />
      <Text color={'#405159'} fontSize={['12px']} textTransform={'upperCase'}>
        <CategoryTitle  category={category} />
      </Text>
    </Flex> 
  )
}

const Categories = ({type, isHidden=false}) => {
  const chart_type = (type == 'GENERATIVE') ?  GENERATIVE_REPORTCHART_TYPES : REPORTCHART_TYPES 
  return (
    <Grid visibility={isHidden ? 'invisible' : 'visible'} gridTemplateColumns={['1fr 1fr 1fr']} gridTemplateRows={['1f 1fr 1fr']} justify={['center']} w={['full']} px={['15px']} py={['15px']}>
      {chart_type.map((category) => <CategoryItem key={category.value} category={category.value} />)}
      <CategoryItem key={'multiple'} category={'MULTIPLE'} />
    </Grid>
  )
}

export const ToothChart = ({data, isDMFT=false, height=360, width=190}) => {
  const [ isFlipped, setIsFlipped ] = useState(storage.getChartFlipped() || false)
  const { category } = useReportStore();

  const toothClasses = {};
  const categoriesCount = {};

  // go through the block data and convert it to tooth charting object
  // object with key of tooth number "txx" and value of category in all caps for class
  (data?.blocks || []).map((block) => {
      const data = block?.data;
      if (category != null){
        // check that the category matches the selected category
        if (toUpper(data.category) == toUpper(category)){
          data.regions.map((region) => {
            if (region.length > 0){
              // will overwrite any previous block styles
              if (toothClasses[`t${region}`]){
                  toothClasses[`t${region}`].push(toUpper(block.data.category))
                  !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
              } else {
                  toothClasses[`t${region}`] = [toUpper(block.data.category)]
              }
            }
          })
        }
      } else {
        // if category is null, then add all categories
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(toUpper(block.data.category))
                !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
            } else {
                toothClasses[`t${region}`] = [toUpper(block.data.category)]
            }
          }
        })
      }
      categoriesCount[toUpper(data.category)] = (categoriesCount[toUpper(data.category)] || 0) + 1
      
  })

  const handleFlip = () => {
    //storage.setChartFlipped(!isFlipped)
    //setIsFlipped(!isFlipped)
  }

  return (
      <Flex 
        w={['full']}
        justify={'center'} 
        background={'#fff'}
        py={['20px']}
        borderRadius={['25px']}
        direction={isFlipped ? 'row-reverse' : 'row'}
        onClick={handleFlip}
      >
        <Flex align={['center']}>
          <Text mr={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.right'}
              defaultMessage={'R'}
              />
          </Text>
        </Flex>
        <ToothSetModern
          toothClasses={toothClasses}
          width={width}
          height={height}
          isFlipped={isFlipped}
        />
        <Flex align={['center']}>
          <Text ml={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.left'}
              defaultMessage={'L'}
              />
          </Text>
        </Flex>
      </Flex>
  )
}

export const ChartSection = ({data, type, height=360, width=190, isHidden=false }) => {
    const categoriesCount = {};

    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (data?.blocks || []).map((block) => {
        categoriesCount[toUpper(block?.data.category)] = (categoriesCount[toUpper(block?.data.category)] || 0) + 1  
    })

    const isDMFT = (type || "").indexOf("DMFT") !== -1

    if (isDMFT || data == null){

      return( 
        <Box  
          px={['20px']}
          py={'50px'}
          pb={'70px'}
          bg={'#fff'}
          border={['1px solid #C7D3D9']}
          minW={['390px']}
          m={['5px']}
          borderRadius={['30px']}
        >
            <ToothChart 
              isDMFT={isDMFT}
              data={data} 
              width={width}
              height={height}
            />
        </Box>
      )
    } else {

      return( 
        <Box  
          px={['20px']}
          bg={'#fff'}
          border={['1px solid #C7D3D9']}
          minW={['390px']}
          m={['5px']}
          borderRadius={['30px']}
        >
          <Flex w={'full'} justify={['end']} pt={['20px']}>
            <CategoriesFilterSelect isDisabled={!data?.blocks} categoriesCount={categoriesCount} isLabelShowing={false} />
          </Flex>
            <ToothChart 
              data={data} 
              width={width}
              height={height}
            />
          <Categories isHidden={(type || "").indexOf("DMFT") !== -1} type={type} />
        </Box>
      )
    }

}

export const ReportSection = ({data, request}) => {
  const { category, setCategory, setBlockData } = useReportStore();
  const editorInstance = useRef(null);
  const chartRef = useRef(null);
  //const [ imageSrc, setImageSrc ] = useState(null);
  const handlePrint = useReactToPrint({
    documentTitle: `${request?.report?.sent_at && request?.report?.sent_at.substring(0, 10)} ${request?.user_profile?.first_name}` ,
    content: () => chartRef.current,
  });

  useEffect(() => {
   // setBlockData(data?.blocks || [])
    /*  if (category != null){
        const newBlocks = (data?.blocks || []).filter((block) => 
          block.data.category.toUpperCase() == category.toUpperCase()
        )
        setBlockData(newBlocks)
        //editorInstance.current.render({blocks: newBlocks})
      } else {
        setBlockData(data?.blocks || [])
        //editorInstance.current.render({blocks: data.blocks})
      }*/
      return () => {}
  }, [data])
  //}, [category, data])

  const setEditorInstance = (instance) => {
    if (editorInstance?.current == null){
        editorInstance.current = instance;
    }
  }

  const isDental = request?.type == "REPORT"
  let isNotReady = (request?.status || "").indexOf("COMPLETE") == -1;

  if (request?.status == "INSPECTING"){
    isNotReady = false;
  }

  if (isNotReady){
    return (
        <Box
          p={0} 
          m={['5px']}
          bg={['#fff']}
          w={['full']}
          borderRadius={['15px']}
        >   
          <Flex mt={['30px']}>
            <Text fontSize={['30px']} color={'#273238'}>
              <FormattedMessage
                  id="requestDetailPage.reportsection.report.title"
                  defaultMessage="Report Notes"
              />
            </Text>
          </Flex>
          <Flex 
            w={'580px'}
            p={['40px']}
            py={['50px']}
            pt={['80px']}
            m={['5px']}
            bg={['#fff']}
            justify={'center'}
            align={'center'}
            borderRadius={['15px']}
            direction={['column']}
          >
            <NoReportIcon />
            <Text mt={['10px']} color={'#6B8A99'} fontSize={['20px']}>
              <FormattedMessage   
                id={'reportDetail.history.noReport'}
                defaultMessage={'No data'}
              />
            </Text>
          </Flex>
        </Box>
    )
  } else if (isDental){
      return (
        <Box>
          <Box
            p={0} 
            m={['5px']}
            bg={['#fff']}
            borderRadius={['15px']}
          >   
            <ReportPrintPreview ref={chartRef} status={request?.status} data={request} />
            <Flex mt={['30px']} justify={'space-between'}>
              <Text fontSize={['30px']} color={'#273238'}>
                <FormattedMessage
                    id="requestDetailPage.reportsection.report.title"
                    defaultMessage="Report Notes"
                />
              </Text>
              <Flex
                align={'center'}
              >
                <Button 
                  variant="ghost"
                  size="sm"
                  leftIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  <FormattedMessage
                    id="reportDetail.history.print"
                    defaultMessage="Print"
                  />
                </Button>
              </Flex>
            </Flex>
              <ReadOnlyEditor 
                editorInstance={editorInstance.current} 
                setEditorInstance={setEditorInstance} 
                data={data}
                isTitleOn={false}
              />
          </Box>
        </Box>
      )
  } else if (data?.blocks && data?.blocks.length == 0) {
    return (
      <HealthyReportSection />
    )
  } else {
    return (
      <Box>
        <Box
          p={0} 
          m={['5px']}
          bg={['#fff']}
          borderRadius={['15px']}
        >   
          <Flex mt={['30px']}>
            <Text fontSize={['30px']} color={'#273238'}>
              <FormattedMessage
                  id="requestDetailPage.reportsection.report.title"
                  defaultMessage="Report Notes"
              />
            </Text>
          </Flex>
            <ReadOnlyEditor 
              editorInstance={editorInstance.current} 
              setEditorInstance={setEditorInstance} 
              data={data}
              isTitleOn={false}
            />
        </Box>
      </Box>
    )
  }
}

export const ReportSectionDMFT = ({data, request, status}) => {
  
  
  const chartRef = useRef(null);
  //const [ imageSrc, setImageSrc ] = useState(null);
  const handlePrint = useReactToPrint({
    documentTitle: `${request?.created_at && request?.created_at?.substring(0, 10)} ${request?.user_profile?.first_name}` ,
    content: () => chartRef.current,
  });

  //console.log("track ReportSectionDMFT ", data, request, status)
  let isNotReady = (request?.status || "").indexOf("COMPLETE") == -1;

  if (request?.status == "INSPECTING"){
    isNotReady = false;
  }

  console.log("ReportSectionDMFT data ", data)

  if (isNotReady){
    return (
        <Box
          p={0} 
          m={['5px']}
          bg={['#fff']}
          w={['full']}
          borderRadius={['15px']}
        >   
          <Flex mt={['15px']}>
            <Text fontSize={['30px']} color={'#273238'}>
              <FormattedMessage
                  id="requestDetailPage.reportsection.report.title"
                  defaultMessage="Report Notes"
              />
            </Text>
          </Flex>
          <Flex 
            p={['40px']}
            pl={[0]}
            py={['50px']}
            pt={['80px']}
            w={'580px'}
            m={['5px']}
            bg={['#fff']}
            justify={'center'}
            align={'center'}
            borderRadius={['15px']}
            direction={['column']}
          >
            <NoReportIcon />
            <Text mt={['10px']} color={'#6B8A99'} fontSize={['20px']}>
              <FormattedMessage   
                id={'reportDetail.history.noReport'}
                defaultMessage={'No data'}
              />
            </Text>
          </Flex>
        </Box>
    )
  } else {
    return (
      <Box>
        <Box
          p={0} 
          m={['5px']}
          bg={['#fff']}
          borderRadius={['15px']}
        >   
          <ReportPrintPreview ref={chartRef} status={status} data={request} />
          <Flex mt={['15px']} justify={'space-between'}>
            <Text fontSize={['30px']} color={'#273238'}>
              <FormattedMessage
                  id="requestDetailPage.reportsection.report.title"
                  defaultMessage="Report Notes"
              />
            </Text>
            <Flex
              align={'center'}
            >
              <Button 
                variant="ghost"
                size="sm"
                leftIcon={<PrintIcon />}
                onClick={handlePrint}
              >
                <FormattedMessage
                  id="reportDetail.history.print"
                  defaultMessage="Print"
                />
              </Button>
            </Flex>
          </Flex>
          <PrintPreviewReportSection
              data={data}
              status={status}
          />
        </Box>
      </Box>
    )
  } 
}
import React from "react";
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { Box, Flex, Heading, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { Pagination } from "./Pagination";
import { FormattedMessage } from "react-intl";
import CenteredSpinner from "../../_components/CenteredSpinner";
import { formatDate, formatCurrencyAmount, numberToFixedDecimal, getTotalCost, getTotalDiscount, getCurrencySymbol } from "../utils";
import { useUserMembershipInvoiceCollection } from "../api/getUserInvoiceCollection";
import { InvoicePaymentButton } from "./InvoicePaymentButton";

const StatusTag = ({status}) => {
    switch (status){
        case "unpaid":
            return (
                <Text color={'#E95252'} textTransform={['uppercase']} fontWeight={700}>
                    <FormattedMessage
                        id={'membershipPage.invoiceList.status.unpaid'}
                        defaultMessage={'Unpaid'}
                    />
                </Text>
            )
        case "paid":
            return (
                <Text color={'#44C5A6'} textTransform={['uppercase']}>
                    <FormattedMessage
                        id={'membershipPage.invoiceList.status.paid'}
                        defaultMessage={'Paid'}
                    />
                </Text>
            )
        default:
            return <></>; // Default style
    }
}



const InvoiceItem = ({row}) => {
    // row.original
    // column.columnDef

    const colorProps = { color: '#7B8887' }
    if (row.original.status == 'unpaid'){
        colorProps.color = '#E95252'
    }

    return (
        <Box borderBottom={'1px solid #E2E8F0'} py={['15px']} px={['10px']}>
            <Flex w={['100%']} justify={['space-between']} align={['center']}>
                <Text fontWeight={700}>
                    {formatDate(row.original.due_date)} 
                </Text>
                <StatusTag status={row.original.status} />
            </Flex>
            <SimpleGrid columns={[2]} mt={['5px']}>
                <Text>
                    <FormattedMessage 
                        id={'membershipPage.invoiceList.totalCost'}
                        defaultMessage={'Total Cost'}
                    />
                </Text>
                <Text textAlign={['right']}> 
                    {`${getCurrencySymbol(row.original.currency)} ${formatCurrencyAmount(numberToFixedDecimal(row.original.total_amount))}`}
                </Text>
            </SimpleGrid>
                {row.original.status === 'unpaid' && (
                    <Flex w={['full']} justify={['center']} pt={['15px']} pb={['5px']}>
                        <InvoicePaymentButton id={row.original?.id} />
                    </Flex>
                )}
        </Box>
    )
}

const PageTable = ({data, isLoading, pagination, setPagination}) => {
    const columns = React.useMemo(
        () => [
            {
                header: 'Date',
                id: 'created_date',
                accessorKey: 'created_date',
            },
            {
                header: 'Total Discount',
                id: 'total_discount',
                accessorFn: row => getTotalDiscount(row?.treatments),
            },
            {
                header: 'Total Cost',
                id: 'total_cost',
                accessorFn: row => getTotalCost(row?.treatments),
            }
        ],
        []
    )
    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.invoices || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })

    return (
        <Box>
            {
                isLoading ? (
                    <CenteredSpinner />
                ) : (table.getRowModel().rows.length === 0) ? (
                    <Box pt={['10px']}>
                        <FormattedMessage 
                            id={'membershipPage.invoiceList.noData'}
                            defaultMessage={'No Invoices'}
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Box key={row.id} >
                                    <InvoiceItem 
                                        row={row}
                                    />
                                </Box>
                            )
                        })}
                    </>
                )
            }
            <Flex w="full" mt={'50px'} justify={['center', 'flex-end']}>
                <Pagination 
                    isLoading={isLoading}
                    canPreviousPage={table.getCanPreviousPage()}
                    canNextPage={table.getCanNextPage()}
                    pageIndex={table.getState().pagination.pageIndex}
                    total={table.getPageCount()}
                    nextPage={table.nextPage}
                    previousPage={table.previousPage}
                    setPageIndex={table.setPageIndex}
                />
            </Flex>
        </Box>
    )
}

export const InvoiceList = () => {
    const PAGE_SIZE = 10;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const { data, isLoading, isFetching } = useUserMembershipInvoiceCollection({
        offset: pagination.pageIndex, pg_sz: pagination.pageSize
    })

    return (

        <Box >
            <Heading >
                <FormattedMessage 
                    id={'membershipPage.invoiceList.title'}
                    defaultMessage={'Invoice List'}
                />
            </Heading>
            <PageTable data={data} isLoading={isLoading || isFetching}  pagination={pagination} setPagination={setPagination}  />
        </Box>
    )
}
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Flex } from '@chakra-ui/react';
import { useLocation, useHistory  } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import {
  getIsProfileLoaded, getIsProfileLoading, getProfile
}
from '../Authentication/_selectors';

import { StyledNavigationBar } from './styles';

import logo from '../_images/logoPDHPrimary.png';
import { useAuth } from 'src/Authentication/useAuth';

export const NavigationBar = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  var currentLocation = location.pathname
  const profileLoaded = useSelector(getIsProfileLoaded);
  const profileLoading = useSelector(getIsProfileLoading);
  const userProfile = useSelector(getProfile);
  if (currentLocation.startsWith("/videochat")){
    return(
      <div></div>
    )
  }
  const handleLogin = () => {
    history.push('/login')
  }
  return (
    <StyledNavigationBar>
      <Flex w={['full']} justify="space-between" align="center">
        <a href="/">
          <Image src={logo} alt="logo" w={'45px'} />
        </a>
        <Flex>
          {
            !user && (
              <Button
                size={'smd'}
                borderRadius={['10px']}
                variant={'steelBlue'}
                onClick={handleLogin}
              >
                <FormattedMessage
                  id={'loginForm.login'}
                  defaultMessage={'Login'}
                />
              </Button>
            )
          }
        </Flex>
      </Flex>
    </StyledNavigationBar>
  )
}
